import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CircularProgress from "@material-ui/core/CircularProgress";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { DialogContent } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';

import Feed_HomePromotion from './Feed_HomePromotion';
//import UploadButton from './UploadButton'

import { API } from "aws-amplify";

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth } from "aws-amplify";
//import config from "../config";
import Amplify from "aws-amplify";

// 'redux/actions/auth'안에 있는 모든 변수(*)의 값을 authActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as authActions from 'redux/actions/auth'
// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

//import './UploadButton.scss' // UploadPhoto 버튼 관련

const styles = theme => ({
  /*
  root: {
      width : '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      //backgroundColor: theme.palette.background.paper,
      marginLeft: 10,
      marginRight: 10,
  },
  */
  root: {
    width : '100%',
    //minWidth: 1080,
    overFlowX : "auto",
    display: 'flex',
    marginTop : theme.spacing(14),  // original 8
    marginBottom : theme.spacing(0),  // original 13
    backgroundColor : 'white'
  },
  mainCard: {
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 800,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  mainCard2: {
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 800, // 기존
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight : theme.spacing(0)
    },
  },
  mainCard3: {
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 800, // 기존
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft : theme.spacing(2),
    marginRight : theme.spacing(0),
    [theme.breakpoints.down('xs')]: {
      marginLeft : theme.spacing(0)
    },
  },
  imageText: {
    fontSize: 100,
    color: '#FFFAFA', // snow
    position: 'absolute',
    [theme.breakpoints.up('xs')]: {
      top: '140px',
    },
    [theme.breakpoints.up('sm')]: {
      top: '200px',
    },
    left: 0,
    width: '100%',
  },
  // 사진 smooth 하게
  mainMediaContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
  },
  // 사진 smooth 하게
  mainMediaWrapper: {
    display: 'flex',
    transition: 'transform 0.5s ease-in-out',
  },
  mainMedia: {
    height: 0,
    //paddingTop: '56.25%', // 16:9(기존),
    paddingTop: '42.857%',
    //width : '100%',
    //height : 400,
    objectFit: 'fill',
  },
  mainMedia1: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '240px', // 600*0.4
    },
    [theme.breakpoints.up('sm')]: {
      height: '288px', // 960*0.3
    },
    [theme.breakpoints.up('md')]: {
      height: '384px', // 1280*0.3
    },
    [theme.breakpoints.up('lg')]: {
      height: '576px', // 1920*0.3
    },
    [theme.breakpoints.up('xl')]: {
      height: '576px', // 1920*0.3
    },
    /*
    [theme.breakpoints.down('xs')]: {
      height: '240px', // 600*0.4
    },
    [theme.breakpoints.up('sm')]: {
      height: '384px', // 960*0.4
    },
    [theme.breakpoints.up('md')]: {
      height: '512px', // 1280*0.4
    },
    [theme.breakpoints.up('lg')]: {
      height: '768px', // 1920*0.4
    },
    [theme.breakpoints.up('xl')]: {
      height: '768px', // 1920*0.4
    },
    */
    /*
    [theme.breakpoints.down('xs')]: {
      height: '300px', // 600*0.5
    },
    [theme.breakpoints.up('sm')]: {
      height: '480px', // 960*0.5
    },
    [theme.breakpoints.up('md')]: {
      height: '640px', // 1280*0.5
    },
    [theme.breakpoints.up('lg')]: {
      height: '960px', // 1920*0.5
    },
    [theme.breakpoints.up('xl')]: {
      height: '960px', // 1920*0.5
    },
    */
  },
  mainMedia2: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    objectFit: 'cover',
    // Add other common styles here
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      height: '200px', // Set your desired height for xs screens
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      height: '300px', // Set your desired height for sm screens
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      height: '400px', // Set your desired height for md screens
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      height: '500px', // Set your desired height for lg screens
    },
    [theme.breakpoints.up('xl')]: {
      width: '100%',
      height: '600px', // Set your desired height for xl screens
    },
  },
  mainMedia3: {
    height: 0,
    //paddingTop: '33.33%', // Original '56.25%'. 16:9 aspect ratio
    width: '100%', // Allow the width to adjust based on container
    objectFit: 'cover', // Maintain aspect ratio and cover the container
    // Add other common styles here
    [theme.breakpoints.down('xs')]: {
      paddingTop: '30%', // Original '100%'
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '56.25%', // Original '75%'
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '56.25%', // Original '56.25%'
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '56.25%', // Original '42.857%'
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: '56.25%', // Original '33.33%'
    },
  },
  /*
  // promotion1.jpg용
  mainMedia4: {
    objectFit: 'contain',
    [theme.breakpoints.down('xs')]: {
      width: '200px',
      height: '280px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '350px',
      height: '490px',
    },
    [theme.breakpoints.up('md')]: {
      width: '450px',
      height: '630px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '500px',
      height: '700px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '500px',
      height: '700px',
    },
  },
  */
  // christmas.jpg용
  mainMedia4: {
    //objectFit: 'contain',
    [theme.breakpoints.down('xs')]: {
      width: '250px',
      height: '280px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '450px',
      height: '490px',
    },
    [theme.breakpoints.up('md')]: {
      width: '550px',
      height: '630px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '550px',
      height: '700px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '550px',
      height: '700px',
    },
  },
  navButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    backgroundColor: 'transparent', // Set background to transparent
    color: 'black', // Set color to black
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'transparent', // Set hover background to transparent
      color: '#696969', // gray
    },
  },
  navButtonLeft: {
    [theme.breakpoints.up('xs')]: {
      left: 30,
    },
    [theme.breakpoints.up('sm')]: {
      left: 60,
    },
    [theme.breakpoints.up('md')]: {
      left: 70,
    },
    [theme.breakpoints.up('lg')]: {
      left: 80,
    },
    [theme.breakpoints.up('xl')]: {
      left: 80,
    },
  },
  navButtonRight: {
    [theme.breakpoints.up('xs')]: {
      right: 50,
    },
    [theme.breakpoints.up('sm')]: {
      right: 60,
    },
    [theme.breakpoints.up('md')]: {
      right: 70,
    },
    [theme.breakpoints.up('lg')]: {
      right: 80,
    },
    [theme.breakpoints.up('xl')]: {
      right: 80,
    },
  },
  arrowbutton: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '25px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '32px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '32px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '40px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '40px',
    },
  },
  cardFrame: {
    width : '100%',
    // maxWidth: 650,
    // height: 30,
    boxShadow: "none",
    background: "#F8F8F8",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  cardTitle: {
    width : '100%',
    // maxWidth: 650,
    height: 30,
    boxShadow: "none",
    background: "#F8F8F8",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  typoTitle1: {
    fontSize : 20,
    backgroundColor : 'white'
  },
  typoTitle2: {
    fontSize : 18,
  },
  typoTitle3: {
    fontSize : 20,
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
    backgroundColor : 'white'
  },
  listIntroduce: {
    width: '100%',
    marginTop: 5,
    padding: 5,
  },
  listItem: {
    paddingTop: 10,
    paddingBottom: 10,
    // height: 60,
  },
  listItemIntroduceTitle: {
    paddingTop: 5,
    paddingBottom: 5
  },
  listItemIntroduceContent: {
    paddingTop: 2,
    paddingBottom: 0
  },
  typoIntroduceTitle: {
    fontSize : 17,
  },
  typoIntroduceContent: {
    fontSize : 16,
  },
  typoIntroduceConetent2: {
    fontSize : 16,
    // color: 'red'
  },
  typoIntroduceContent3: {
    fontSize : 13,
  },
  invoiceButton: {
    opacity: 0.7,
    position: 'fixed', 
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    cursor: 'pointer',
    right: 15,
    bottom: 30,
    marginBottom: 50,
    /*
    [theme.breakpoints.down('md')]: {
      bottom: 20,
      marginBottom: 20,
    },
    [theme.breakpoints.up('md')]: {
      bottom: 10,
      marginBottom: 10,
    },
    */
    [theme.breakpoints.up('xs')]: {
      width: 100,
      height: 100,
      borderRadius: 50,
    },
    [theme.breakpoints.up('sm')]: {
      width: 100,
      height: 100,
      borderRadius: 50,
    },
    [theme.breakpoints.up('md')]: {
      width: 150,
      height: 150,
      borderRadius: 150,
    },
    [theme.breakpoints.up('lg')]: {
      width: 200,
      height: 200,
      borderRadius: 200,
    },
    [theme.breakpoints.up('xl')]: {
      width: 200,
      height: 200,
      borderRadius: 200,
    },
    backgroundColor: '#00BFFF',
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#1E90FF',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#1E90FF',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  invoiceLogoMedia : {
    opacity: 0.7,
    position: 'fixed', 
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    cursor: 'pointer',
    marginBottom: 65,
    [theme.breakpoints.up('xs')]: {
      width: 50,
      height: 50,
      right: 20,
      bottom: 25,
      //marginBottom: 20,
    },
    [theme.breakpoints.up('sm')]: {
      width: 50,
      height: 50,
      right: 20,
      bottom: 25,
      //marginBottom: 20,
    },
    [theme.breakpoints.up('md')]: {
      width: 75,
      height: 75,
      right: 30,
      bottom: 25,
      //marginBottom: 20,
    },
    [theme.breakpoints.up('lg')]: {
      width: 90,
      height: 90,
      right: 50,
      bottom: 35,
      //marginBottom: 30,
    },
    [theme.breakpoints.up('xl')]: {
      width: 90,
      height: 90,
      right: 50,
      bottom: 35,
      //marginBottom: 30,
    },
  },
  invoiceTypo : {
    opacity: 0.7,
    fontSize : 25,
    color: 'black',
    [theme.breakpoints.up('xs')]: {
      fontSize : 20,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
    },
    [theme.breakpoints.up('md')]: {
      fontSize : 23,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize : 30,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize : 30,
    },
  },
  submitButton: {
    width: 150,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  closeButton: {
    marginTop: theme.spacing(2),
    width: 150,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  /*
  iconAvatar: {
    backgroundColor: '#0000FF',
    margin: 10,
    width: 100,
    height: 100,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  */
  progress: {
    //margin: theme.spacing(2),
    // position: 'absolute',
    position: 'fixed',
    //left: '50%', 
    top: '65%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});

/*
const uploadContents = [
  {
    'id' : 1,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '홍길동',
    'date' : '2019.09.02',
    'image' : 'http://placeimg.com/64/64/1',
    'content' : '홍길동 바보',
  },
  {
    'id' : 2,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '장발장',
    'date' : '2019.05.05',
    'image' : 'http://placeimg.com/64/64/2',
    'content' : '장발장 멍청이',
  },
  {
    'id' : 3,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '김철수',
    'date' : '2019.03.03',
    'image' : 'http://placeimg.com/64/64/3',
    'content' : '김철수 바보',
  },
  {
    'id' : 4,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '장희빈',
    'date' : '2019.01.02',
    'image' : 'http://placeimg.com/64/64/4',
    'content' : '장희빈 바보',
  },
  {
    'id' : 5,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '김희선',
    'date' : '2018.12.25',
    'image' : 'http://placeimg.com/64/64/5',
    'content' : '김희선 바보',
  }
]
*/

const images = {
  xs: ['main_home_1.jpg', 'main_home_2.png', 'main_home_3.png'], // Image filenames for xs screen size
  sm: ['main_home_1.jpg', 'main_home_2.png', 'main_home_3.png'], // Image filenames for sm screen size
  md: ['main_home_1.jpg', 'main_home_2.png', 'main_home_3.png'], // Image filenames for md screen size
  lg: ['main_home_1.jpg', 'main_home_2.png', 'main_home_3.png'], // Image filenames for lg screen size
  xl: ['main_home_1.jpg', 'main_home_2.png', 'main_home_3.png'], // Image filenames for xl screen size
};


class Home_Main extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        goodSuppliers: '',
        newSuppliers: '',
        image: "",
        userId: "",
        notiUserId: "",
        searchKeyword: '',
        // user page 방식
        //page1: 1,
        page2: 1,
        //pagelimit1: 4,
        pagelimit2: 8,
        hasNextPage1: false,
        hasNextPage2: false,
        userContents: "",
        getNotifications: "",
        fcmTokenId: "",
        alarmDialogOpen_1: false,
        alarmDialogOpen_2: false,
        alarmDialogOpen_3: false,
        completed : 0,
        notiTrigger : false,  // false 일때만 서버로 전송
        currentIndex: 0, // main image 변환
      };

      this.imageChangeInterval = null;
  }

  getGoodSuppliers() {
    //return API.get("upload", `/user?page=${this.state.page1}&pagelimit=${this.state.pagelimit1}&level=1`)
    return API.get("upload", `/user?level=1`)
    .then(goodSuppliers => this.setState({goodSuppliers : goodSuppliers}));
  }

  getNewSuppliers() {
    return API.get("upload", `/user?page=${this.state.page2}&pagelimit=${this.state.pagelimit2}&adminConfirm=true&level=0`)
  }

  // 경로 ( /user/:id) 
  getUser() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/user/${currentAuthUserFromSession}`);
  }

  /*
  getNotifications() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
    return API.get("upload", `/notification/?userId=${currentAuthUserFromSession}&fcmTokenId=${currentFCMTokenFromSession}`)
    .then(getNotifications => this.setState({getNotifications : getNotifications}));
  }
  */

  getNotification() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/notification/${currentAuthUserFromSession}`)
  }

  postNotification(postNotification) {
    return API.post("upload", "/notification", {
      body: postNotification
    });
  }

  putNotification(putNotification) {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.put("upload", `/notification/${currentAuthUserFromSession}`, {
      body: putNotification
    });
  }

  getNotiSetting() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/notiSetting/${currentAuthUserFromSession}`)
  }

  postNotiSetting(postNotificationSet) {
    return API.post("upload", "/notiSetting", {
      body: postNotificationSet
    });
  }

  // 삭제 API
  deleteNotification() {
    // const currentFCMTokenUUIDFromSession = sessionStorage.getItem("currentFCMTokenUUID");
    const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
    return API.del("upload", `/notification/${currentFCMTokenFromSession}`);
  }

  isAndroid() {
    //alert('android')
    return /Android/i.test(navigator.userAgent);
  }

  isIOS() {
    //alert('ios')
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  handleMobileGoogleLogin = async() => {
    localStorage.setItem("mobileGoogleLoginStart", 'False');
    try{
      window.ReactNativeWebView.postMessage(JSON.stringify({ type:'GLRedirect', message:'GLRedirectmessage' }))
    } catch(e) {
      ;
    }
    this.setState({
      alarmDialogOpen_2 : false,
    });
  }

  handleMobileKakaoLogin = async() => {
    localStorage.setItem("mobileKakaoLoginStart", 'False');
    try{
      window.ReactNativeWebView.postMessage(JSON.stringify({ type:'KKRedirect', message:'KKRedirectmessage' }))
    } catch(e) {
      ;
    }
    this.setState({
      alarmDialogOpen_2 : false,
    });
  }

  handleClose = async() => {
    this.setState({
      alarmDialogOpen_1 : false,
      alarmDialogOpen_2 : false,
      alarmDialogOpen_3 : false,
    });
  }

  
  async getExpo_AWSCredentials(expoGoogleUser) {
    //handleGoogleSignIn = async (response) => {
      //console.log("googleUser: ", googleUser)
      const { userHasAuthenticatedTrue } = this.props
      
      //const { uid, email, displayName, stsTokenManager, id_token } = expoGoogleUser;
      const { email, displayName, accessToken, expirationTime, savedIdToken } = expoGoogleUser;

      //alert('googleLogin_android_expoGoogleUser: ' + JSON.stringify(expoGoogleUser))
      //alert('googleLogin_android_email_home: ' + JSON.stringify(email))
      //alert('googleLogin_android_displayName: ' + JSON.stringify(displayName))
      //alert('googleLogin_android_stsTokenManager: ' + JSON.stringify(stsTokenManager))
      //alert('googleLogin_android_stsTokenManager.accessToken: ' + JSON.stringify(stsTokenManager.accessToken))
      //alert('googleLogin_android_stsTokenManager.expirationTime: ' + JSON.stringify(stsTokenManager.expirationTime))
      //alert('googleLogin_android_id_token: ' + JSON.stringify(id_token))
      //alert('googleLogin_android_expirationTime: ' + JSON.stringify(expirationTime))
      //alert('googleLogin_android_accessToken: ' + JSON.stringify(accessToken))
      //alert('googleLogin_android_savedIdToken: ' + JSON.stringify(savedIdToken))

      const currentTime = Date.now();
      const expiresIn = expirationTime - currentTime;

      let user = {
          email: email,
          name: displayName
      };
  
      localStorage.setItem("email", email);
      //alert('email: ' + JSON.stringify(email))
  
      this.setState({ isLoading: true });
      
      try {
        const credentials = await Auth.federatedSignIn(
          'google',
          //{ token: stsTokenManager.accessToken, expires_at: expirationTime },
          { token: savedIdToken, expires_at: currentTime + expiresIn },
          user
        );

        //alert('googleLogin_android_credentials: ' + JSON.stringify(credentials))
      } catch(e) {
        console.log(e)
        //alert('googleLogin_android_credentials_error: ' + JSON.stringify(e))
      }
      
      this.setState({ isLoading: false });
      
      try {
        const userID = await Auth.currentAuthenticatedUser();
        // console.log("userID: ");
        // console.log(userID);
        const userID2 = await Auth.currentUserInfo(userID);
        // console.log("userID2: ");
        // console.log(userID2);
        if(userID2 === null) {
          const userID3 = JSON.stringify(userID);
          // console.log("userID3: " + userID3);
          const userID4 = userID3.substr(userID3.indexOf('"id":"'));
          // console.log("userID4: " + userID4);
          const userID5 = userID4.substr(6);
          // console.log("userID5: " + userID5);
          const userID6 = userID5.substr(0, userID5.indexOf('"'));
  
          // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
          sessionStorage.setItem("currentUser", userID6);
          //alert('expocurrentuser: ' + JSON.stringify(expocurrentuser))
  
          userHasAuthenticatedTrue();
        } else if (userID2 != null) {
          const userID3 = JSON.stringify(userID2);
          // console.log("userID3: " + userID3);
          const userID4 = userID3.substr(userID3.indexOf('"id":"'));
          // console.log("userID4: " + userID4);
          const userID5 = userID4.substr(6);
          // console.log("userID5: " + userID5);
          const userID6 = userID5.substr(0, userID5.indexOf('"'));
  
          // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
          sessionStorage.setItem("currentUser", userID6);
          //alert('expocurrentuser: ' + JSON.stringify(expocurrentuser))
  
          //const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
  
          //console.log("google-currentAuthUserFromSession :")
          //console.log(currentAuthUserFromSession)
          userHasAuthenticatedTrue();
        }
      } catch (e) {
          if (e !== "not authenticated") {
            // alert(e);
            alert('not authenticated: ' + JSON.stringify(e))
            console.log(e)
          }
      }
      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
      //alert('currentAuthUserFromSession: ' + JSON.stringify(currentAuthUserFromSession))

      localStorage.setItem("loginType", "google");

      const loginType_FromLocal = localStorage.getItem("loginType");
      const customerType1_FromLocal = localStorage.getItem("customerType1");
      const customerType2_FromLocal = localStorage.getItem("customerType2");
      const email_FromLocal = localStorage.getItem("email");
  
      if(customerType1_FromLocal === undefined || customerType1_FromLocal === 'undefined' || customerType1_FromLocal === null
      || customerType1_FromLocal === undefined || customerType2_FromLocal === 'undefined' || customerType2_FromLocal === null
      || email_FromLocal === undefined || email_FromLocal === 'undefined' || email_FromLocal === null) {
        try {
          const user = await this.getUser();
        
          const { userId, customerType1, customerType2, email } = user;

          //console.log("userId_google: ")
          //console.log(userId)

          if(userId === currentAuthUserFromSession) {
            localStorage.setItem("loginType", "google");
            localStorage.setItem("customerType1", customerType1);
            localStorage.setItem("customerType2", customerType2);
            localStorage.setItem("email", email);
            /*
            const customerType1_FromLocal = localStorage.getItem("customerType1");
            const customerType2_FromLocal = localStorage.getItem("customerType2");
            const email_FromLocal = localStorage.getItem("email");
            console.log("customerType1_FromLocal :")
            console.log(customerType1_FromLocal)
            console.log("customerType2_FromLocal :")
            console.log(customerType2_FromLocal)
            console.log("email_FromLocal :")
            console.log(email_FromLocal)
            */
          //} else if(this.state.userId === undefined && loginType_FromLocal != 'email') {
          } else if(this.state.userId === undefined || this.state.userId === 'undefined' || this.state.userId === null) {
            console.log("userId is undefined_google");
            // console.log('currentUser: ' + currentUser);
            // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            // console.log('userId: ' + this.state.userId);
            // this.props.history.push(`/user/${this.props.currentUser}`);
            /*
            this.setState({
              alarmDialogOpen_1 : true,
            });
            */
          }
          else {
            console.log("userId is not matched_google");
            //console.log('currentUser: ' + currentUser);
            //console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            //console.log('userId: ' + this.state.userId);
            this.props.history.push("/signupbuyerfreelancergoogle"); // google 회원 가입 필요
          } 
        } catch(e) {
          console.log(e)
          // console.log('getUser 없음')
        }
      } else {
        // console.log("User 정보 받아옴")
        /*
         console.log("customerType1_FromLocal :")
         console.log(customerType1_FromLocal)
         console.log("customerTyp2_FromLocal :")
         console.log(customerType2_FromLocal)
         console.log("email_FromLocal :")
         console.log(email_FromLocal)
         */
      }
      
      //sessionStorage.setItem("FCMLoginToggle", "true");
  
      //this.props.onLogin(credentials);  // 이 코드를 넣어 줘야 로그인 되면서 페이지 넘어간다.
  
      this.props.history.push("/"); // 로그인 후 home 페이지로 이동
  }

  // main image 변환
  startImageChangeInterval = () => {
    this.imageChangeInterval = setInterval(this.handleNextImage, 5000);
  };

  // main image 변환
  clearImageChangeInterval = () => {
    clearInterval(this.imageChangeInterval);
  };

  // main image 변환
  handleNextImage = () => {
    const { currentIndex } = this.state;
    const screenSize = 'xs'; // You can dynamically determine the screen size
    this.setState({
      currentIndex: (currentIndex + 1) % images[screenSize].length,
    });
  };
  // main image 변환

  handlePreviousImage = () => {
    const { currentIndex } = this.state;
    const screenSize = 'xs'; // You can dynamically determine the screen size
    this.setState({
      currentIndex:
        currentIndex === 0 ? images[screenSize].length - 1 : currentIndex - 1,
    });
  };
  
  componentWillUnmount() {
    this.clearImageChangeInterval();  // main image 변환
  }

  // 모든 component가 구성이 완료 되고 불러온다.
  // 이유는 서버에서 미처 불러올 시간이 안되고 _get()함수가 실행되어 버리면 내용을 못불러올 수 있기 때문이다.
  async componentDidMount() {
    const { isAuthenticated } = this.props

    // 팝업 promotion
    this.setState({
      alarmDialogOpen_3 : false, // 팝업 promotion (true일때). popup 안하려면 false로
    });

    this.startImageChangeInterval();  // main image 변환

    if(isAuthenticated === true) {
      //console.log("isAuthenticated === true")
    } else {
      // Cognito 로그인 없이 S3 읽어 온다.
      Amplify.configure({
        Auth: {
          mandatorySignIn: false, // true이면 로그인 시에만 S3 읽어 올수 있다.
        }
      });
    }

    //localStorage.removeItem("loginType");

    const mobileGoogleLoginStart = localStorage.getItem("mobileGoogleLoginStart");

    if (mobileGoogleLoginStart == "True") {
      this.setState({
        alarmDialogOpen_2 : true,
      });
    }

    
    try {
      if (this.isAndroid()) {
        const receiver = document;
        receiver.addEventListener('message', async (e) => {
          const event = JSON.parse(e.data) 
          //alert('googleLogin1: ' + JSON.stringify(event))
          if (event.type == 'googleLoginFinish') {
            //alert('googleLogin_android: ' + JSON.stringify(event.type))

            this.getExpo_AWSCredentials(event.message);
            userHasAuthenticatedTrue();
          }
          // expo에서 받은 데이터로 구글 로그인 진행

        })
      }
    } catch(e) {
      ;
    }
    
    this._isMounted = true;
    const { userHasAuthenticatedTrue } = this.props;
    localStorage.setItem("currentPage", "homePromotion");

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    // 만약 progress바를 확인하고 싶으면 _get() 함수를 지우면 확인 가능하다.

    try {
      const userID = await Auth.currentAuthenticatedUser();
      // console.log("userID: ");
      // console.log(userID);
      const userID2 = await Auth.currentUserInfo(userID);
      // console.log("userID2: ");
      // console.log(userID2);
      if(userID2 === null) {
        const userID3 = JSON.stringify(userID);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);

        userHasAuthenticatedTrue();
      } else if (userID2 != null) {
        const userID3 = JSON.stringify(userID2);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);

        userHasAuthenticatedTrue();
      }
    } catch (e) {
        if (e !== "not authenticated") {
          // alert(e);
          console.log(e)
        }
    }

    await this.getGoodSuppliers();
    /*
    const getGoodSupplier =  await this.getGoodSuppliers();
    //const { docs, hasNextPage } = getGoodSupplier;
    const docs1 = getGoodSupplier.docs
    const hasNextPage1 = getGoodSupplier.hasNextPage
    this.setState({goodSuppliers: docs1})
    this.setState({hasNextPage1: hasNextPage1})
    */

    const getNewSupplier =  await this.getNewSuppliers();
    // const { docs, hasNextPage } = getNewSupplier;
    const docs2 = getNewSupplier.docs
    const hasNextPage2 = getNewSupplier.hasNextPage
    this.setState({newSuppliers: docs2})
    this.setState({hasNextPage2: hasNextPage2})

    // home 업로드시 관련 내용들
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    //console.log("currentAuthUserFromSession: ")
    //console.log(currentAuthUserFromSession)
    //alert('currentAuthUserFromSession: ' + JSON.stringify(currentAuthUserFromSession))

    const loginType_FromLocal = localStorage.getItem("loginType");
    const customerType1_FromLocal = localStorage.getItem("customerType1");
    const customerType2_FromLocal = localStorage.getItem("customerType2");
    const email_FromLocal = localStorage.getItem("email");
    
    /*
    console.log("loginType_FromLocal: ")
    console.log(loginType_FromLocal)
    console.log("customerType1_FromLocal: ")
    console.log(customerType1_FromLocal)
    console.log("customerType2_FromLocal: ")
    console.log(customerType2_FromLocal)
    console.log("email_FromLocal: ")
    console.log(email_FromLocal)
    */

    if (loginType_FromLocal == 'google') {
      if(customerType1_FromLocal === undefined || customerType1_FromLocal === 'undefined' || customerType1_FromLocal === null
      || customerType1_FromLocal === undefined || customerType2_FromLocal === 'undefined' || customerType2_FromLocal === null
      || email_FromLocal === undefined || email_FromLocal === 'undefined' || email_FromLocal === null) {
        try {
          const user = await this.getUser();
        
          const { userId, customerType1, customerType2, email } = user;

          //console.log("userId_google: ")
          //console.log(userId)

          if(userId === currentAuthUserFromSession) {
            localStorage.setItem("loginType", "google");
            localStorage.setItem("customerType1", customerType1);
            localStorage.setItem("customerType2", customerType2);
            localStorage.setItem("email", email);
            /*
            const customerType1_FromLocal = localStorage.getItem("customerType1");
            const customerType2_FromLocal = localStorage.getItem("customerType2");
            const email_FromLocal = localStorage.getItem("email");
            console.log("customerType1_FromLocal :")
            console.log(customerType1_FromLocal)
            console.log("customerType2_FromLocal :")
            console.log(customerType2_FromLocal)
            console.log("email_FromLocal :")
            console.log(email_FromLocal)
            */
          //} else if(this.state.userId === undefined && loginType_FromLocal != 'email') {
          } else if(userId === undefined || userId === 'undefined' || userId === null) {
            console.log("userId is undefined_google");
            // console.log('currentUser: ' + currentUser);
            // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            // console.log('userId: ' + this.state.userId);
            // this.props.history.push(`/user/${this.props.currentUser}`);
            this.props.history.push("/signupformbuyerfreelancerfederatedlogin"); // google 회원 가입 필요
            /*
            this.setState({
              alarmDialogOpen_1 : true,
            });
            */
          }
          else {
            console.log("userId is not matched_google");
            //console.log('currentUser: ' + currentUser);
            //console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            //console.log('userId: ' + this.state.userId);
            this.props.history.push("/signupformbuyerfreelancerfederatedlogin"); // google 회원 가입 필요
          } 
        } catch(e) {
          console.log(e)
          // console.log('getUser 없음')
        }
      } else {
        // console.log("User 정보 받아옴")
        /*
         console.log("customerType1_FromLocal :")
         console.log(customerType1_FromLocal)
         console.log("customerTyp2_FromLocal :")
         console.log(customerType2_FromLocal)
         console.log("email_FromLocal :")
         console.log(email_FromLocal)
         */
      }
    }

    if (loginType_FromLocal == 'kakaotalk') {
      if(customerType1_FromLocal === undefined || customerType1_FromLocal === 'undefined' || customerType1_FromLocal === null
      || customerType1_FromLocal === undefined || customerType2_FromLocal === 'undefined' || customerType2_FromLocal === null
      || email_FromLocal === undefined || email_FromLocal === 'undefined' || email_FromLocal === null) {
        try {
          const user = await this.getUser();
        
          const { userId, customerType1, customerType2, email } = user;

          //console.log("userId_kakaotalk: ")
          //console.log(userId)

          if(userId === currentAuthUserFromSession) {
            localStorage.setItem("loginType", "kakaotalk");
            localStorage.setItem("customerType1", customerType1);
            localStorage.setItem("customerType2", customerType2);
            localStorage.setItem("email", email);
            /*
            const customerType1_FromLocal = localStorage.getItem("customerType1");
            const customerType2_FromLocal = localStorage.getItem("customerType2");
            const email_FromLocal = localStorage.getItem("email");
            console.log("customerType1_FromLocal :")
            console.log(customerType1_FromLocal)
            console.log("customerType2_FromLocal :")
            console.log(customerType2_FromLocal)
            console.log("email_FromLocal :")
            console.log(email_FromLocal)
            */
          //} else if(this.state.userId === undefined && loginType_FromLocal != 'email') {
          } else if(userId === undefined || userId === 'undefined' || userId === null) {
            console.log("userId is undefined_kakaotalk");
            // console.log('currentUser: ' + currentUser);
            // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            // console.log('userId: ' + this.state.userId);
            // this.props.history.push(`/user/${this.props.currentUser}`);
            /*
            this.setState({
              alarmDialogOpen_1 : true,
            });
            */
          }
          else {
            console.log("userId is not matched_kakaotalk");
            //console.log('currentUser: ' + currentUser);
            //console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            //console.log('userId: ' + this.state.userId);
            this.props.history.push("/signupbuyerfreelancergoogle"); // google 회원 가입 필요
          } 
        } catch(e) {
          console.log(e)
          // console.log('getUser 없음')
          console.log("userId is not matched_kakaotalk");
          //console.log('currentUser: ' + currentUser);
          //console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
          //console.log('userId: ' + this.state.userId);
          this.props.history.push("/signupformbuyerfreelancerfederatedlogin"); // google 회원 가입 필요
        }
      } else {
        // console.log("User 정보 받아옴")
        /*
         console.log("customerType1_FromLocal :")
         console.log(customerType1_FromLocal)
         console.log("customerTyp2_FromLocal :")
         console.log(customerType2_FromLocal)
         console.log("email_FromLocal :")
         console.log(email_FromLocal)
         */
      }
    }

    if (loginType_FromLocal == 'email') {
      if(customerType1_FromLocal === undefined || customerType1_FromLocal === 'undefined' || customerType1_FromLocal === null
      || customerType1_FromLocal === undefined || customerType2_FromLocal === 'undefined' || customerType2_FromLocal === null
      || email_FromLocal === undefined || email_FromLocal === 'undefined' || email_FromLocal === null) {
        try {
          const user = await this.getUser();
        
          const { userId, customerType1, customerType2, email } = user;

          //console.log("userId: ")
          //console.log(userId)

          if(userId === currentAuthUserFromSession) {
            localStorage.setItem("loginType", "email");
            localStorage.setItem("customerType1", customerType1);
            localStorage.setItem("customerType2", customerType2);
            localStorage.setItem("email", email);
            /*
            const customerType1_FromLocal = localStorage.getItem("customerType1");
            const customerType2_FromLocal = localStorage.getItem("customerType2");
            const email_FromLocal = localStorage.getItem("email");
            console.log("customerType1_FromLocal :")
            console.log(customerType1_FromLocal)
            console.log("customerType2_FromLocal :")
            console.log(customerType2_FromLocal)
            console.log("email_FromLocal :")
            console.log(email_FromLocal)
            */
          //} else if(this.state.userId === undefined && loginType_FromLocal != 'email') {
          } else if(userId === undefined) {
            // console.log("userId is undefined");
            // console.log('currentUser: ' + currentUser);
            // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            // console.log('userId: ' + this.state.userId);
            // this.props.history.push(`/user/${this.props.currentUser}`);
            this.setState({
              alarmDialogOpen_1 : true,
            });
          }
          else {
            console.log("userId is not matched");
            //console.log('currentUser: ' + currentUser);
            //console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            //console.log('userId: ' + this.state.userId);
            this.setState({
              alarmDialogOpen_1 : true,
            });
          } 
        } catch(e) {
          console.log(e)
          // console.log('getUser 없음')
        }
      } else {
        // console.log("User 정보 받아옴")
        /*
         console.log("customerType1_FromLocal :")
         console.log(customerType1_FromLocal)
         console.log("customerTyp2_FromLocal :")
         console.log(customerType2_FromLocal)
         console.log("email_FromLocal :")
         console.log(email_FromLocal)
         */
      }
    }

    if (loginType_FromLocal != 'google' && loginType_FromLocal != 'email') {
      if(customerType1_FromLocal === undefined || customerType1_FromLocal === 'undefined' || customerType1_FromLocal === null
      || customerType1_FromLocal === undefined || customerType2_FromLocal === 'undefined' || customerType2_FromLocal === null
      || email_FromLocal === undefined || email_FromLocal === 'undefined' || email_FromLocal === null) {
        try {
          const user = await this.getUser();
        
          const { userId, customerType1, customerType2, email } = user;

          //console.log("userId: ")
          //console.log(userId)

          if(userId === currentAuthUserFromSession) {
            localStorage.setItem("loginType", "email");
            localStorage.setItem("customerType1", customerType1);
            localStorage.setItem("customerType2", customerType2);
            localStorage.setItem("email", email);
            /*
            const customerType1_FromLocal = localStorage.getItem("customerType1");
            const customerType2_FromLocal = localStorage.getItem("customerType2");
            const email_FromLocal = localStorage.getItem("email");
            console.log("customerType1_FromLocal :")
            console.log(customerType1_FromLocal)
            console.log("customerType2_FromLocal :")
            console.log(customerType2_FromLocal)
            console.log("email_FromLocal :")
            console.log(email_FromLocal)
            */
          //} else if(this.state.userId === undefined && loginType_FromLocal != 'email') {
          } else if(userId === undefined) {
            // console.log("userId is undefined");
            // console.log('currentUser: ' + currentUser);
            // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            // console.log('userId: ' + this.state.userId);
            // this.props.history.push(`/user/${this.props.currentUser}`);
            this.setState({
              alarmDialogOpen_1 : true,
            });
          }
          else {
            console.log("userId is not matched");
            //console.log('currentUser: ' + currentUser);
            //console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            //console.log('userId: ' + this.state.userId);
            this.setState({
              alarmDialogOpen_1 : true,
            });
          } 
        } catch(e) {
          console.log(e)
          // console.log('getUser 없음')
        }
      } else {
        // console.log("User 정보 받아옴")
        /*
         console.log("customerType1_FromLocal :")
         console.log(customerType1_FromLocal)
         console.log("customerTyp2_FromLocal :")
         console.log(customerType2_FromLocal)
         console.log("email_FromLocal :")
         console.log(email_FromLocal)
         */
      }
    }

    //-----------------------------------------------------------------------------------------
    // FCM notification (fcm token)
    //-----------------------------------------------------------------------------------------
    //const FCMToggleFromSession = sessionStorage.getItem("FCMLoginToggle");
    // react native 통신 (android)
    try {
      if (this.isAndroid()) {
        const receiver = document;
        receiver.addEventListener('message', async (e) => {
          const event = JSON.parse(e.data) 
          window.ReactNativeWebView.postMessage(JSON.stringify(event));
          //alert('data_android: ' + JSON.stringify(event))
          //alert('data2: ' + JSON.stringify(event.data))
          if (event.type == 'fcmtoken' && this.state.notiTrigger == false) {
            //alert('reactjs_fcmtoken_android: ' + JSON.stringify(event.message))            
            // notification에 fcmtoken 저장 하기
            //alert('check1: ' + JSON.stringify(event.type))
            try {
              //sessionStorage.removeItem("currentFCMToken")  // 테스트용
              //sessionStorage.setItem("currentFCMToken", 'abcde') // 테스트용
              const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
              //alert('check2: ' + JSON.stringify(currentFCMTokenFromSession))
              if(currentFCMTokenFromSession == null) {
                //alert('check3')
                const getnotification = await this.getNotification();
                //alert('getnotification: ' + getnotification)
                const { userId } = getnotification;
                this.setState({notiUserId : userId});
                //alert('userId_checked: ' + JSON.stringify(this.state.notiUserId))
              
                // 만약 userId가 겁색이 되어서 현재 userId와 같으면 수정
                if(this.state.notiUserId == currentAuthUserFromSession) {
                  //alert('check4: ' + JSON.stringify(event.message))
                  await this.putNotification({
                    fcmTokenId: event.message,
                  });
                  sessionStorage.setItem("currentFCMToken", event.message)
                  //alert('check5: ' + JSON.stringify(event.message))
                  this.setState({notiTrigger : true});
                }
                //alert('check6')
              }
              if (currentFCMTokenFromSession != null && currentFCMTokenFromSession != event.message && currentAuthUserFromSession) {
                //alert('check7: ' + JSON.stringify(event.message))
                await this.putNotification({
                  fcmTokenId: currentFCMTokenFromSession,
                });
                sessionStorage.setItem("currentFCMToken", event.message)
                //alert('check8: ' + JSON.stringify(event.message))
                this.setState({notiTrigger : true});
              }
            } catch(e) {
              //alert('check9')
              try {
                // 만약 userId가 검색 되지 않으면 post
                const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
                if(currentFCMTokenFromSession == null && !this.state.notiUserId && currentAuthUserFromSession){
                  //alert('check10: ' + JSON.stringify(event.message))
                  // 만약 검색이 되지 않으면 post로 새로 저장하기
                  await this.postNotification({
                    fcmTokenId: event.message,
                    userId: currentAuthUserFromSession,
                  });
                  sessionStorage.setItem("currentFCMToken", event.message)
                  //alert('check11: ' + JSON.stringify(event.message))
                  this.setState({notiTrigger : true});
                }
              } catch(e) {
                //alert('check12')
              }
              //alert('check13')
            }
          }
        });
      }
    } catch(e) {
      ;
    }
    
    // react native 통신 (ios)
    try{
      if (this.isIOS()) {
        const receiver = window;
        receiver.addEventListener('message', async (e) => {
          const event = JSON.parse(e.data) 
          window.ReactNativeWebView.postMessage(JSON.stringify(event));
          //alert('data_ios: ' + JSON.stringify(event))
          //alert('data2: ' + JSON.stringify(event.data))
          if (event.type == 'fcmtoken' && this.state.notiTrigger == false) {
            //alert('reactjs_fcmtoken_ios: ' + JSON.stringify(event.message))
            // notification에 fcmtoken 저장 하기
            //alert('check1: ' + JSON.stringify(event.type))
            try {
              //sessionStorage.removeItem("currentFCMToken")  // 테스트용
              //sessionStorage.setItem("currentFCMToken", 'abcde') // 테스트용
              const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
              //alert('check2: ' + JSON.stringify(currentFCMTokenFromSession))
              if(currentFCMTokenFromSession == null) {
                //alert('check3')
                const getnotification = await this.getNotification();
                //alert('getnotification: ' + getnotification)
                const { userId } = getnotification;
                this.setState({notiUserId : userId});
                //alert('userId_checked: ' + JSON.stringify(this.state.notiUserId))
              
                // 만약 userId가 겁색이 되어서 현재 userId와 같으면 수정
                if(this.state.notiUserId == currentAuthUserFromSession) {
                  //alert('check4: ' + JSON.stringify(event.message))
                  await this.putNotification({
                    fcmTokenId: event.message,
                  });
                  sessionStorage.setItem("currentFCMToken", event.message)
                  //alert('check5: ' + JSON.stringify(event.message))
                  this.setState({notiTrigger : true});
                }
                //alert('check6')
              }
              if (currentFCMTokenFromSession != null && currentFCMTokenFromSession != event.message && currentAuthUserFromSession) {
                //alert('check7: ' + JSON.stringify(event.message))
                await this.putNotification({
                  fcmTokenId: currentFCMTokenFromSession,
                });
                sessionStorage.setItem("currentFCMToken", event.message)
                //alert('check8: ' + JSON.stringify(event.message))
                this.setState({notiTrigger : true});
              }
            } catch(e) {
              //alert('check9')
              try {
                // 만약 userId가 검색 되지 않으면 post
                const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
                if(currentFCMTokenFromSession == null && !this.state.notiUserId && currentAuthUserFromSession){
                  //alert('check10: ' + JSON.stringify(event.message))
                  // 만약 검색이 되지 않으면 post로 새로 저장하기
                  await this.postNotification({
                    fcmTokenId: event.message,
                    userId: currentAuthUserFromSession,
                  });
                  sessionStorage.setItem("currentFCMToken", event.message)
                  //alert('check11: ' + JSON.stringify(event.message))
                  this.setState({notiTrigger : true});
                }
              } catch(e) {
                //alert('check12')
              }
              //alert('check13')
            }
          } 
        });
      }
    } catch(e) {
      ;
    }

    /*
    // 기존 web기반 코드. 나중에 삭제하기
    if(currentFCMTokenFromSession && currentAuthUserFromSession && FCMToggleFromSession === "true") {
      await this.postNotification({
        fcmTokenId: currentFCMTokenFromSession,
        userId: currentAuthUserFromSession,
      });
      //console.log("FCM 토큰값 DB로 저장(로그인)");
      sessionStorage.setItem("FCMLoginToggle", "false");
     
      Object.keys(this.state.getNotifications).map(notificationUUId => {
        const getNotification = this.state.getNotifications[notificationUUId];
        const currentFCMTokenUUID = getNotification.notificationUUId;
        sessionStorage.setItem("currentFCMTokenUUID", currentFCMTokenUUID);
      });
      
    } else {
      //console.log("FCM 토큰값 없음 또는 이미 토큰값 DB에 저장됨");
      sessionStorage.setItem("FCMLoginToggle", "false");
    }
    */
    //-----------------------------------------------------------------------------------------
    // FCM notification (end)
    //-----------------------------------------------------------------------------------------

    // notiSetting 관련
    const notiSettingUserId = localStorage.getItem("notiSetting")
    ? JSON.parse(localStorage.getItem("notiSetting")).userId
    : null

    if(notiSettingUserId === currentAuthUserFromSession) {
      //console.log("localStorge_notiSetting 있음.");
      try{
        const localEventToggle = JSON.parse(localStorage.getItem("notiSetting")).eventToggle
        const localApplicantToggle = JSON.parse(localStorage.getItem("notiSetting")).applicantToggle  // 내가 게재한 공고에 지원자가 지원시 알림 설정
        //const localFollowUploadToggle = JSON.parse(localStorage.getItem("notiSetting")).followUploadToggle
        //const localFollowCommentToggle = JSON.parse(localStorage.getItem("notiSetting")).followCommentToggle
        this.setState({
          eventToggle : localEventToggle,
          applicantToggle : localApplicantToggle,
          // followUploadToggle : localFollowUploadToggle,
          // followCommentToggle : localFollowCommentToggle,
        });
      } catch(e) {
        //console.log("localNoniSetting() 에러.");    
      }
    } else {
      //console.log("localStorge_notiSetting 없음.");
      try{
        const notificationSetting = await this.getNotiSetting();

        const { userId, eventToggle, applicantToggle, /*followUploadToggle, followCommentToggle*/ } = notificationSetting;
        
        this.setState({userId});
  
        if(this.state.userId === currentAuthUserFromSession)
        {
          this.setState({ eventToggle, applicantToggle, /*followUploadToggle, followCommentToggle*/ });
        }

        localStorage.setItem(
          "notiSetting",
          JSON.stringify({
            userId: currentAuthUserFromSession,
            eventToggle: this.state.eventToggle,
            applicantToggle: this.state.applicantToggle,
            /*
            followUploadToggle: this.state.followUploadToggle,
            followCommentToggle: this.state.followCommentToggle
            */
          })
        )
        //console.log("localStorge_notiSetting 저장됨.");
      } catch(e) {
        // alert(e);        
        await this.postNotiSetting({
          eventToggle: "true",
          applicantToggle: "true",
          /*
          followUploadToggle: "true",
          followCommentToggle: "true",
          */
        });

        localStorage.setItem(
          "notiSetting",
          JSON.stringify({
            userId: currentAuthUserFromSession,
            eventToggle: "true",
            applicantToggle: "true",
            /*
            followUploadToggle: "true",
            followCommentToggle: "true",
            */
          })
        )
        
        //console.log("postNotiSetting() 실행됨.");
      }
    }
  }

  drawerOpen = () => {
    const {drawertoggletrue} = this.props;
    drawertoggletrue();
    try{
      window.ReactNativeWebView.postMessage(JSON.stringify({ type:'fcmtokentrigger', message:'fcmtokentriggerdata' }))
      //window.ReactNativeWebView.postMessage(JSON.stringify({ type:'GLRedirect', message:'GLRedirectmessage' }))
    } catch(e) {
      ;
    }
  }

  goToNaver = () => {
    window.open('https://blog.naver.com/cjchiness')  // 새로운 탭에서 열림
    // window.location.href = 'https://hangouts.google.com'; // 현재 탭에서 열림
  }

  goToYoutube = () => {
    window.open('https://youtube.com/@mingmenschool')  // 새로운 탭에서 열림
    // window.location.href = 'https://hangouts.google.com'; // 현재 탭에서 열림
  }

  // 다이얼 로그
  // true에서 false로, false에서 true로 스위치 할 수 있는 간단한 함수
  handleDialogToggle = () => this.setState({
   dialog: !this.state.dialog
  })

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 말그대로 state를 초기화 해주면 되는 함수
  stateRefresh = () => {
    this.setState({
    uploadContents : '',
    userContents : '',
    completed : 0,
    });
  }
 
  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  handleGoToPage = async() => {
    const loginType = localStorage.getItem("loginType")
    
    const { userHasAuthenticatedFalse } = this.props
    /*
    // DB에 FCM Token 삭제 API
    try {
      await this.deleteNotification();
      sessionStorage.setItem("FCMLoginToggle", "false");
      // window.location.reload();
    } catch (e) {
      // alert(e);
      console.log("deleteNotification() 에러")
      sessionStorage.setItem("FCMLoginToggle", "false");
    }
    // 로컬스트로리지 삭제
    // localStorage.removeItem("notiChecking"); // <- 로그아웃해도 notiChecking관련 localstoge는 안 지울것임. 나중에 재 로그인 위해서
    */

    // android가 아니고 ios가 아니면 web 로그아웃 진행
    if (!this.isAndroid() && !this.isIOS()) {
      // 로그아웃시 세션 지우기 기능
      await Auth.signOut()

      if (loginType === "facebook") {
        // 페이스북 로그아웃
        window.FB.logout();
        console.log("facebook Log Out!");
        this.props.history.push(`/chooseaccount`);
        window.location.reload();
      } else if (loginType === "google") {
        // 구글 로그아웃
        // this.googleSignOut();
        document.location.href="https://accounts.google.com/logout?continue=https://appengine.google.com/_ah/logout?continue=https://www.otwogong.com/chooseaccount";
        // document.location.href="https://accounts.google.com/logout?continue=https://appengine.google.com/_ah/logout?continue=https://localhost:5555";
        console.log("google Log Out!");
      }
    }

    // android 또는 ios 이면 로그아웃 진행
    if (this.isAndroid() || this.isIOS()) {
      //alert('Mobile login');
    }
    

    userHasAuthenticatedFalse();
    //sessionStorage.setItem("currentUser", null);

    // console.log("Log Out!");

    sessionStorage.clear()
    localStorage.clear()

  this.setState({
    alarmDialogOpen_1 : false,
  });
}

  render() {
    const { classes, theme, } = this.props;
    const { searchKeyword, currentIndex } = this.state;

    const isXs = window.innerWidth < 600; // You can adjust this breakpoint
    const isSm = window.innerWidth >= 600 && window.innerWidth < 960;
    const isMd = window.innerWidth >= 960 && window.innerWidth < 1280;
    const isLg = window.innerWidth >= 1280 && window.innerWidth < 1920;
    const isXl = window.innerWidth >= 1920;

    const screenSize = 'xs'; // You can dynamically determine the screen size

    const imageSrc = `/images/main/${images[screenSize][currentIndex]}`;

    const wrapperPosition = `-${currentIndex * (100 / images[screenSize].length)}%`;

    /*
    // 각 데이터를 필터를 거친후 만들어주게끔 설정하는 함수
    const filteredGoodSuppliers = (data) => {
      data = data.filter((c) => {
        return c.companyName.indexOf(searchKeyword) > -1;
      });
      return data.map((c) => {

        return(
          <Feed_HomePromotion
            stateRefresh={this.stateRefresh}
            key={c.userUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
            id={c.id}
            userId={c.userId}
            userUUId={c.userUUId}
            customerType1={c.customerType1}
            customerType2={c.customerType2}
            companyName={c.companyName}
            name={c.name}
            history={c.history}
            address={c.address}
            avatarimage={c.avatarimage}
            adminConfirm={c.adminConfirm}
          />
        );
      })
    }

    // 각 데이터를 필터를 거친후 만들어주게끔 설정하는 함수
    const filteredNewSuppliers = (data) => {
      data = data.filter((c) => {
        return c.companyName.indexOf(searchKeyword) > -1;
      });
      return data.map((c) => {

        return(
          <Feed_HomePromotion
            stateRefresh={this.stateRefresh}
            key={c.userUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
            id={c.id}
            userId={c.userId}
            userUUId={c.userUUId}
            customerType1={c.customerType1}
            customerType2={c.customerType2}
            companyName={c.companyName}
            name={c.name}
            history={c.history}
            address={c.address}
            avatarimage={c.avatarimage}
            adminConfirm={c.adminConfirm}
          />
        );
      })
    }
    */

    return (
      <div className={classes.root}>
        {/*<Grid container spacing={0} direction="row" align = "center" justify="flex-start" alignItems="flex-start">*/}
        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
          <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center"> {/* 세로로 정렬시 사용 */}
              <Card className={classes.mainCard}>
                <div className={classes.mainMediaContainer}>
                  <IconButton
                    className={`${classes.navButton} ${classes.navButtonLeft}`}
                    onClick={this.handlePreviousImage}
                  >
                    <ArrowBackIosIcon className={classes.arrowbutton}/>
                  </IconButton>
                  <div
                    className={classes.mainMediaWrapper}
                    style={{
                      transform: `translateX(${wrapperPosition})`,
                      width: `${images[screenSize].length * 100}%`,
                      transition: 'transform 0.5s ease-in-out',
                    }}
                  >
                    {images[screenSize].map((image, index) => (
                      <CardMedia
                        key={index}
                        className={classes.mainMedia1}
                        image={`/images/main/${image}`}
                      />
                    ))}
                  </div>
                    <IconButton
                      className={`${classes.navButton} ${classes.navButtonRight}`}
                      onClick={this.handleNextImage}
                    >
                      <ArrowForwardIosIcon className={classes.arrowbutton}/>
                    </IconButton>
                </div>
              </Card>
              {/*
              <Card className={classes.mainCard}>
                <CardMedia
                  className={classes.mainMedia1}
                  image={imageSrc}
                  /
                  image={
                    isXs ? '/images/main/main_home.jpg' :
                    isSm ? '/images/main/main_home.jpg' :
                    isMd ? '/images/main/main_home.jpg' :
                    isLg ? '/images/main/main_home.jpg' :
                    isXl ? '/images/main/main_home.jpg' : '/images/main/main_home.jpg'
                  }
                  /
                />
              </Card>
              */}
              {/*
              <img
                className={classes.mainMedia2}
                src={'/images/main/main_home.jpg'}  // 테스트시 사용
              />
              */}
            </Grid>         
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Card className={classes.cardTitle}>
                <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography className={classes.typoTitle1}>
                      명문중국어전문학원 블로그 소식
                    </Typography>
                  </Grid>
                  { isXs
                  ?
                  <div></div>
                  :
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography className={classes.typoTitle1}>
                      명문중국어전문학원 유튜브 소식
                    </Typography>
                  </Grid>
                  }
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Card className={classes.mainCard2}>
                <Link className={classes.drawerlink} onClick={this.goToNaver} component={RouterLink}>
                  {isXs
                  ?
                  <CardMedia
                    className={classes.mainMedia3}
                    image={
                      isXs ? '/images/main/naver2.png' :
                      isSm ? '/images/main/naver2.png' :
                      isMd ? '/images/main/naver2.png' :
                      isLg ? '/images/main/naver2.png' :
                      isXl ? '/images/main/naver2.png' : '/images/main/naver2.png'
                    }
                  />
                  :
                  <CardMedia
                    className={classes.mainMedia3}
                    image={
                      isXs ? '/images/main/naver1.png' :
                      isSm ? '/images/main/naver1.png' :
                      isMd ? '/images/main/naver1.png' :
                      isLg ? '/images/main/naver1.png' :
                      isXl ? '/images/main/naver1.png' : '/images/main/naver1.png'
                    }
                  />
                  }
                </Link>
              </Card>
            </Grid>
            {isXs
            ?
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography className={classes.typoTitle3}>
                명문중국어전문학원 유튜브 소식
              </Typography>
            </Grid>
            :
            <div></div>
            }
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Card className={classes.mainCard3}>
                <Link className={classes.drawerlink} onClick={this.goToYoutube} component={RouterLink}>
                  <CardMedia
                    className={classes.mainMedia3}
                    image={
                      isXs ? '/images/main/youtube.png' :
                      isSm ? '/images/main/youtube.png' :
                      isMd ? '/images/main/youtube.png' :
                      isLg ? '/images/main/youtube.png' :
                      isXl ? '/images/main/youtube.png' : '/images/main/youtube.png'
                    }
                  />
                </Link>
              </Card>
            </Grid>
          </Grid>
          {/*
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            {this.state.goodSuppliers
              ?
               // filteredComponents(this.state.publicUploadContents)
               Object.keys(this.state.goodSuppliers).map(userUUId => {
                 const c = this.state.goodSuppliers[userUUId];
   
                 return(
                  <Feed_HomePromotion
                    stateRefresh={this.stateRefresh}
                    key={c.userUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
                    id={c.id}
                    userId={c.userId}
                    userUUId={c.userUUId}
                    customerType1={c.customerType1}
                    customerType2={c.customerType2}
                    companyName={c.companyName}
                    name={c.name}
                    history={c.history}
                    address={c.address}
                    avatarimage={c.avatarimage}
                    adminConfirm={c.adminConfirm}
                  />
                );
               })
              :
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
              </Grid>
            }
          </Grid>
          */}
          {/*
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Card className={classes.cardTitle}>
                <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography className={classes.typoTitle1}>
                      신규기업회원
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    /<Button onClick={this.drawerOpen} className={classes.typoTitle2}>/
                    <Typography className={classes.typoTitle2}>
                      가입을 축하드립니다.
                    </Typography>
                    /</Button>/
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          */}
          {/*
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            {this.state.newSuppliers
              ?
              //filteredNewSuppliers(this.state.newSuppliers)
              Object.keys(this.state.newSuppliers).map(userUUId => {
                const c = this.state.newSuppliers[userUUId];
  
                return(
                 <Feed_HomePromotion
                   stateRefresh={this.stateRefresh}
                   key={c.userUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
                   id={c.id}
                   userId={c.userId}
                   userUUId={c.userUUId}
                   customerType1={c.customerType1}
                   customerType2={c.customerType2}
                   companyName={c.companyName}
                   name={c.name}
                   history={c.history}
                   address={c.address}
                   avatarimage={c.avatarimage}
                   adminConfirm={c.adminConfirm}
                 />
               );
              })
              :
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
              </Grid>
            }
          </Grid>
          */}
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.listIntroduce}>
                <Divider/>
                <ListItem className={classes.listItemIntroduceTitle}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceTitle}>명문중국어전문학원</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceContent}>사업자등록번호 : 563-90-02209</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceContent}>원장 : 장리리</Typography>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.listIntroduce}>
                <Divider/>
                <ListItem className={classes.listItemIntroduceTitle}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceTitle}>충북 청주시 흥덕구 가경로 5 502호</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceConetent2}>043)900-0515</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceConetent2}>mygg777@naver.com</Typography>
                  </Grid>
                </ListItem>
                {/*
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceContent3}>평일 09:00 ~ 18:00 / 주말·공휴일 휴무</Typography>
                  </Grid>
                </ListItem>
                */}
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Dialog open={this.state.alarmDialogOpen_1} onClose={this.handleGoToPage}>
          <DialogTitle>웹(앱)을 이용하시기 위해서는 사용자 정보가 필요합니다.</DialogTitle>
          <DialogContent>정상적인 회원가입이 아닌 간편로그인으로 들어오셔서 추가적인 회원정보 작성이 필요합니다.</DialogContent>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.submitButton}
                  onClick={this.handleGoToPage}>회원정보 페이지로 가기</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_2} onClose={this.handleMobileGoogleLogin}>
        <DialogTitle>구글 로그인</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.submitButton}
                  onClick={this.handleMobileGoogleLogin}>구글 로그인</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        {/*
        /promotion1.jpg용/
        <Dialog open={this.state.alarmDialogOpen_3} onClose={this.handleClose}>
          {/
          <DialogTitle>광고</DialogTitle>
          <DialogContent>광고.</DialogContent>
          /}
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <CardMedia
                  className={classes.mainMedia4}
                  image="/images/popup/promotion1.jpg"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" /color="primary"/ className={classes.closeButton}
                  onClick={this.handleClose}>창닫기</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        */}
        {/*christmas.jpg용 (앞으로 이코드 사용하기)*/}
        <Dialog open={this.state.alarmDialogOpen_3} onClose={this.handleClose}>
          {/*
          <DialogTitle>광고</DialogTitle>
          <DialogContent>광고.</DialogContent>
          */}
          <DialogActions>
            <CardMedia
              className={classes.mainMedia4}
              image="/images/popup/hsk.png"
              //image="/images/popup/christmas.jpg"
            />
          </DialogActions>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.closeButton}
                  onClick={this.handleClose}>창닫기</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        {/*
        <Button onClick={this.drawerOpen} className={classes.invoiceButton}>
          <Typography className={classes.invoiceTypo}>
            분야 클릭
          </Typography>
        </Button>
        <CardMedia
          className={classes.invoiceLogoMedia}
          // image="/static/images/logo/app.svg"
          image="/images/logo/app.svg"
          aria-label="Recipe"
          onClick={this.drawerOpen}
        />
        */}
        {/*
        // 업로드 버튼. 단, 현재는 UploadPhoto.js에서 currentpage에 관한 코딩이 안되어 있다.
        // 차후 필요시 UploadPhoto.js 파일 수정하면 됨
        {this.state.uploadButtonToggle / 프로젝트에는 기업들만 업로드 할 수 있게 할 예정 /
        ?
        <Link component={RouterLink} to='/upload' underline='none' color='inherit'>
          <button
            className="UploadButton"
            onClick={this.handleUpload}
          >
          </button>
        </Link>
        :
        null
        }
        */}
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isdrawerToggle: state.upload.isdrawerToggle,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
  drawertoggletrue: () => dispatch(uploadActions.drawertoggletrue()),
  drawertogglefalse: () => dispatch(uploadActions.drawertogglefalse()),
})

Home_Main = withStyles(styles, {withTheme: true})(Home_Main)
export default connect(mapStateToProps, mapDispatchToProps)(Home_Main)